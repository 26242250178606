import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import BgImage from "../../../components/BgImage"

const MainSection = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "home/main-hero-bg.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1280, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <BgImage
      title="Seu carro mais completo"
      fluid={data.hero.childImageSharp.fluid}
      overlayColor="transparent"
      className="section section-main"
      mobileHeight="640px"
    >
      <div className="container">
        <h1 className="title has-text-white">
          <span className="has-text-blue">
            Seu <br /> carro
          </span>
          <br className="is-hidden-desktop" /> mais{" "}
          <br className="is-hidden-mobile" />
          completo
        </h1>
      </div>
    </BgImage>
  )
}

export default MainSection
